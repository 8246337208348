import React from "react"
import MapIcon from "../../icons/map"
import User from "../../icons/user"
import Clipboard from "../../icons/clipboard"
import BookOpen from "../../icons/book-open"
import * as styles from "./profile.module.scss"
import MailIcon from "../../icons/mail"
import Button from "../button/button"
import ReactMarkdown from "react-markdown"
//#d3d2d6
const Profile = props => {
  return (
    <div className={styles.profile}>
      <img
        className={styles.grayImage}
        alt={`${props.name}`}
        src={props.image}
      />
      {props.expandedUrl ? (
        <h2 className={styles.name}>
          <a href={props.expandedUrl} target="_blank" rel="noopener noreferrer">
            {props.name}
          </a>
        </h2>
      ) : (
        <h2 className={styles.name}>{props.name}</h2>
      )}
      <div className={styles.location}>
        <MapIcon
          style={{ marginBottom: "-2px", marginRight: "2px" }}
          size={14}
        />
        {props.location}
      </div>
      {props.type ? (
        <div className={styles.location}>
          <Clipboard 
            style={{ marginBottom: "-2px", marginRight: "2px" }} 
            size={14}
          />
          {props.type}
        </div>
        ) : (
        <div className={styles.location}>
          <Clipboard 
            style={{ marginBottom: "-2px", marginRight: "2px" }} 
            size={14}
          />
          Guest
        </div>
      )}
      {props.supervisor ? (
        <div className={styles.location}>
          <User 
            style={{ marginBottom: "-2px", marginRight: "2px" }} 
            size={14}
          />
          {props.supervisor}
        </div>
        ) : (
          <div></div>
      )}
      {props.field ? (
        <div className={styles.location}>
          <BookOpen 
            style={{ marginBottom: "-2px", marginRight: "2px" }} 
            size={14}
          />
          {props.field}
        </div>
        ) : (
          <div></div>
      )}
      <ReactMarkdown
        className={styles.description}
        children={props.description}
      />
      <Button
        href={`mailto:${props.email}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          "--background": "#d3d2d6",
          gridColumn: "1 / -1",
          marginTop: "auto",
          marginBottom: 0,
        }}
      >
        <MailIcon size={14}/>
      </Button>
    </div>
  )
}

export default Profile
