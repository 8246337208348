import React from "react"
import { graphql } from "gatsby"
import classnames from "classnames"
import * as styles from "./members.module.scss"
import Profile from "../components/profile/profile"
import PageHeader from "../components/page-header"
import SubHeader from "../components/sub-header"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Members = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const persons = data.members.edges
  const advisors = data.advisors.edges
  const alumni = data.alumni.edges
  const students = data.students.edges
  const postdocs = data.postdocs.edges
  const ltvs = data.ltvs.edges
  return (
    <Layout title={siteTitle}>
      <SEO title="Members" />
      <PageHeader headerText="Members" />
      <div className="mw-80 center ph3 ph4-m ph5-l">
        <SubHeader headerText="Professors" />
        <section className="f1 measure db center lh-copy">
          <div className={classnames({ [styles.profiles]: true })}>
            {persons.map(({ node }) => {
              return (
                <Profile
                  hex="357edd"
                  name={node.frontmatter.person}
                  type = {node.frontmatter.memberType}
                  supervisor = {node.frontmatter.supervisor}
                  field = {node.frontmatter.field}
                  location={node.frontmatter.location}
                  displayUrl={node.frontmatter.displayUrl}
                  expandedUrl={node.frontmatter.url}
                  email={node.frontmatter.email}
                  image={node.frontmatter.avatar.publicURL}
                  description={node.internal.content}
                />
                )
              })}
          </div>
        </section>
        <SubHeader headerText="Advisors" />
        <section className="f1 measure db center lh-copy">
          <div className={classnames({ [styles.profiles]: true })}>
            {advisors.map(({ node }) => {
              return (
                <Profile
                  hex="357edd"
                  name={node.frontmatter.person}
                  type = {node.frontmatter.memberType}
                  supervisor = {node.frontmatter.supervisor}
                  field = {node.frontmatter.field}
                  location={node.frontmatter.location}
                  displayUrl={node.frontmatter.displayUrl}
                  expandedUrl={node.frontmatter.url}
                  email={node.frontmatter.email}
                  image={node.frontmatter.avatar.publicURL}
                  description={node.internal.content}
                />
                )
              })}
          </div>
        </section>
        <SubHeader headerText="Postdoctoral Researchers" />
        <section className="f1 measure db center lh-copy">
          <div className={classnames({ [styles.profiles]: true })}>
            {postdocs.map(({ node }) => {
              return (
                <Profile
                  hex="357edd"
                  name={node.frontmatter.person}
                  type = {node.frontmatter.memberType}
                  supervisor = {node.frontmatter.supervisor}
                  field = {node.frontmatter.field}
                  location={node.frontmatter.location}
                  displayUrl={node.frontmatter.displayUrl}
                  expandedUrl={node.frontmatter.url}
                  email={node.frontmatter.email}
                  image={node.frontmatter.avatar.publicURL}
                  description={node.internal.content}
                />
              )
            })}
          </div>
        </section>
        <SubHeader headerText="Students" />
        <section className="f1 measure db center lh-copy">
          <div className={classnames({ [styles.profiles]: true })}>
            {students.map(({ node }) => {
              return (
                <Profile
                  hex="357edd"
                  name={node.frontmatter.person}
                  type = {node.frontmatter.memberType}
                  supervisor = {node.frontmatter.supervisor}
                  field = {node.frontmatter.field}
                  location={node.frontmatter.location}
                  displayUrl={node.frontmatter.displayUrl}
                  expandedUrl={node.frontmatter.url}
                  email={node.frontmatter.email}
                  image={node.frontmatter.avatar.publicURL}
                  description={node.internal.content}
                />
              )
            })}
          </div>
        </section>
        <SubHeader headerText="Long-Term Visitors" />
        <section className="f1 measure db center lh-copy">
          <div className={classnames({ [styles.profiles]: true })}>
            {ltvs.map(({ node }) => {
              return (
                <Profile
                  hex="357edd"
                  name={node.frontmatter.person}
                  type = {node.frontmatter.memberType}
                  supervisor = {node.frontmatter.supervisor}
                  field = {node.frontmatter.field}
                  location={node.frontmatter.location}
                  displayUrl={node.frontmatter.displayUrl}
                  expandedUrl={node.frontmatter.url}
                  email={node.frontmatter.email}
                  image={node.frontmatter.avatar.publicURL}
                  description={node.internal.content}
                />
              )
            })}
          </div>
        </section>
        <SubHeader headerText="Alumni" />
        <section className="f1 measure db center lh-copy">
          <div className={classnames({ [styles.profiles]: true })}>
            {alumni.map(({ node }) => {
              return (
                <Profile
                  hex="357edd"
                  name={node.frontmatter.person}
                  type = {node.frontmatter.memberType}
                  supervisor = {node.frontmatter.supervisor}
                  field = {node.frontmatter.field}
                  location={node.frontmatter.location}
                  displayUrl={node.frontmatter.displayUrl}
                  expandedUrl={node.frontmatter.url}
                  email={node.frontmatter.email}
                  image={node.frontmatter.avatar.publicURL}
                  description={node.internal.content}
                />
              )
            })}
          </div>
        </section>
      </div>
	 
    </Layout>
  )
}

export default Members

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    members: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "professor" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {

          frontmatter {
            person
            memberType
            supervisor
            field
            location
            url
            displayUrl
            email
            avatar {
              publicURL
            }
          }
          html
          internal {
            content
          }
        }
      }
    }
    advisors: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "advisor" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {

          frontmatter {
            person
            memberType
            supervisor
            field
            location
            url
            displayUrl
            email
            avatar {
              publicURL
            }
          }
          html
          internal {
            content
          }
        }
      }
    }
    alumni: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "alumnus" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {

          frontmatter {
            person
            memberType
            supervisor
            field
            location
            url
            displayUrl
            email
            avatar {
              publicURL
            }
          }
          html
          internal {
            content
          }
        }
      }
    }
    ltvs: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "ltv" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {

          frontmatter {
            person
            memberType
            supervisor
            field
            location
            url
            displayUrl
            email
            avatar {
              publicURL
            }
          }
          html
          internal {
            content
          }
        }
      }
    }
	  students: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "student" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {

          frontmatter {
            person
            memberType
            supervisor
            field
            location
            url
            displayUrl
            email
            avatar {
              publicURL
            }
          }
          html
          internal {
            content
          }
        }
      }
    }
	  postdocs: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "postdoc" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {

          frontmatter {
            person
            memberType
            supervisor
            field
            location
            url
            displayUrl
            email
            avatar {
              publicURL
            }
          }
          html
          internal {
            content
          }
        }
      }
    }
  }
`
