// extracted by mini-css-extract-plugin
export var description = "profile-module--description--7Jiff";
export var flipCard = "profile-module--flip-card--OXONs";
export var flipCardInner = "profile-module--flip-card-inner--3vIGk";
export var grayImage = "profile-module--grayImage--cWBHv";
export var image = "profile-module--image--zXNsd";
export var linkText = "profile-module--linkText--gVcml";
export var location = "profile-module--location--DZ5Fi";
export var name = "profile-module--name--dYMW0";
export var profile = "profile-module--profile--efWxu";
export var url = "profile-module--url--L35Yp";